@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=League+Gothic&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

* {
  font-family: Inter, sans-serif;
  letter-spacing: -.3px;
  line-height: 140%;

  pointer-events: all;
}

/* Custom scrollbar for Webkit-based browsers */
::-webkit-scrollbar {
  width: 8px; /* Half the width of the default scrollbar, adjust as needed */
}

::-webkit-scrollbar-track {
  min-height: 60px;
  @apply bg-mib-beige-light /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  min-height: 60px;
  @apply bg-mib-brown-dark /* Color of the scrollbar handle */
}

/* Remove scrollbar buttons/arrows (not universally supported) */
::-webkit-scrollbar-button {
  display: none;
}

